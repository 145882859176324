<!--
name    : APP CLIENT SERVICE REQUEST CREATE

type    : view

uses    : header-view
          main-container
          job-site-picker
          main-card
          address-validation-map

route   : /clients/view/:uuid/ServiceRequestCreate
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-client-service-request-create">
    <header-view
      title="New Service Request"
      short_title="New SR"
      :btnOptions="[
        {
          name: 'Submit',
          action: handleSubmit,
          btnColor: 'button-primary',
        },
      ]"
      :previous_page="'/clients/view/' + uuid + '?tab=serviceRequests'" />
    <main-container v-if="serviceRequest">
      <main-card>
        <v-form
          ref="form"
          @submit.prevent="handleSubmit"
          class="mt-2"
          id="serviceRequestForm">
          <v-row v-if="client">
            <v-col :cols="12" :sm="6">
              <v-row>
                <v-col :cols="12" :sm="12">
                  <div class="title">Client</div>
                  <div>
                    {{ serviceRequest.requestor_name }}
                  </div>
                  <div>
                    {{ serviceRequest.requestor_phone }}
                  </div>
                  <div>
                    {{ serviceRequest.requestor_email }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :sm="12">
                  <div class="title">Billing Address</div>
                  <div>
                    {{ serviceRequest.address }}
                  </div>
                  <div>
                    {{ serviceRequest.city }}, {{ serviceRequest.state }}
                    {{ serviceRequest.zip_code }}
                  </div>
                  <div>
                    {{ serviceRequest.country }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="12" :sm="6">
              <v-btn-toggle v-model="newJobSite" mandatory color="primary">
                <v-btn @click="clearJobSite()"> New Job Site </v-btn>
                <v-btn
                  :disabled="disableSelectJobSite"
                  @click="jobSitePickerDialog = true">
                  Existing Job Site
                </v-btn>
              </v-btn-toggle>
              <v-text-field
                id="job_site_name"
                v-model="jobsite.name"
                label="Site Name"
                :disabled="disableAddress" />

              <!-- address search and display map -->
              <!-- any selected address from existing jobsite will be passed in via addressSearch -->
              <address-validation-map
                v-on:jobSiteValidated="updateNewJobSite"
                :disabled="disableAddress"
                :addressSearch="jobSiteAddressString"
                :rules="validate_address" />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="12">
              <v-textarea
                id="special_instructions"
                v-model="serviceRequest.special_instructions"
                label="Special Instructions" />
            </v-col>
          </v-row>
        </v-form>
      </main-card>
    </main-container>
    <job-site-picker
      v-model="jobSitePickerDialog"
      :items="jobsites"
      @change="selectExistingJobSite" />
  </div>
</template>

<script>
  // components
  import JobSitePicker from '@/components/jobsites/job-site-picker';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import AddressValidationMap from '@/components/address-validation-map';

  // mixins
  import addresses from '@/mixins/addresses';
  import email from '@/mixins/email';
  import Forms from '@/mixins/forms.js';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppClientServiceRequestCreate',
    components: {
      'job-site-picker': JobSitePicker,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      'address-validation-map': AddressValidationMap,
    },
    mixins: [addresses, email, Forms],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        newJobSite: 0,
        errorMessage: '',
        loader: false,
        loading4: false,
        serviceRequest: null,
        client: null,
        jobsite: {},
        jobsites: [],
        submitted: false,
        jobSitePickerDialog: false,
        jobSiteAddressString: '',
      };
    },
    computed: {
      disableAddress: function () {
        if (this.jobsite.uuid) {
          return true;
        }
        return false;
      },
      disableSelectJobSite: function () {
        if (!this.jobsites || this.jobsites.length == 0) {
          return true;
        }
        return false;
      },
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 3000);
        this.loader = null;
      },
    },
    async created() {
      if (!this.uuid) {
        console.log('client service request create: no uuid was passed in!');
        return;
      }

      // create new service request object
      this.serviceRequest = Jobs.blankServiceRequest();

      this.serviceRequest.client_uuid = this.uuid;
      this.serviceRequest.tenant_uuid = this.$auth.userProfile.tenant_uuid;

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get client
      const res_client = await Clients.getClient(this.uuid, accessToken);

      // validate and assign
      if (res_client) {
        this.client = res_client;
        this.serviceRequest.requestor_name = this.client.contact_name;
        this.serviceRequest.requestor_phone = this.client.contact_phone;
        this.serviceRequest.requestor_email = this.client.contact_email;
        this.serviceRequest.address = this.client.address;
        this.serviceRequest.city = this.client.city;
        this.serviceRequest.state = this.client.state;
        this.serviceRequest.zip_code = this.client.zip_code;
        this.serviceRequest.country = this.client.country;

        // get jobsites for client (if client has any)
        this.jobsites = await Clients.getJobSites(
          {
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
            client_uuid: this.uuid,
          },
          accessToken
        );
      }
    },
    methods: {
      // gets updated by selecting new jobsite via address-validation-map
      updateNewJobSite(newAddress) {
        if (newAddress) {
          this.jobsite.address = newAddress.address;
          this.jobsite.city = newAddress.city;
          this.jobsite.state = newAddress.state;
          this.jobsite.zip_code = newAddress.zip_code;
          this.jobsite.country = newAddress.country;
        }
      },
      // triggers by selecting new jobsite after previously selecting
      // existing jobsite
      clearJobSite() {
        this.jobsite.uuid = undefined;
        this.jobsite.name = '';
        this.jobsite.address = '';
        this.jobsite.city = '';
        this.jobsite.state = '';
        this.jobsite.zip_code = '';
        this.jobsite.country = '';

        // clears address in address-validation-map which
        // was previously set my selecting an existing jobsite
        this.jobSiteAddressString = '';
      },
      selectExistingJobSite(selectedJobsite) {
        this.jobsite.uuid = selectedJobsite.uuid;
        this.jobsite.name = selectedJobsite.name;
        this.jobsite.address = selectedJobsite.address;
        this.jobsite.city = selectedJobsite.city;
        this.jobsite.state = selectedJobsite.state;
        this.jobsite.zip_code = selectedJobsite.zip_code;
        this.jobsite.country = selectedJobsite.country;

        if (
          this.jobsite &&
          this.jobsite.address &&
          this.jobsite.city &&
          this.jobsite.state &&
          this.jobsite.zip_code &&
          this.jobsite.country
        ) {
          // sends display address string to address-validation-map
          this.jobSiteAddressString = this.addressToString(this.jobsite);
        }
      },
      async handleSubmit() {
        // const currentUuid = this.serviceRequest.uuid;

        this.loader = 'loading4';
        if (!this.$refs.form.validate()) {
          return false;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // if its a new job site, create it
        if (!this.jobsite.uuid) {
          this.jobsite.uuid = Clients.newJobSiteUUID();
          this.jobsite.client_uuid = this.serviceRequest.client_uuid;
          this.jobsite.tenant_uuid = this.serviceRequest.tenant_uuid;

          const res_jobsite = await Clients.createJobSite(
            this.jobsite,
            accessToken
          );
          if (res_jobsite) {
            this.serviceRequest.job_site_uuid = res_jobsite.uuid;
          }
        } else {
          this.serviceRequest.job_site_uuid = this.jobsite.uuid;
        }

        let res = await Jobs.createServiceRequest(
          this.serviceRequest,
          accessToken
        );
        if (res) {
          this.submitted = true;

          this.$router.push({
            name: 'ServiceRequestView',
            params: {
              uuid: res.uuid,
            },
          });

          // var url =
          //   window.location.protocol +
          //   '//' +
          //   window.location.hostname +
          //   '/serviceRequests/view/' +
          //   currentUuid;

          // this.createEvent({
          //   link: url,
          //   users: [this.serviceRequest.client_uuid],
          //   notification_name: 'SERVICE-REQUEST-SUBMITTED',
          //   clientUuid: this.serviceRequest.client_uuid,
          // });
        } else {
          this.errorMessage = 'failed to create service request';
          console.log('failed to create service request');

          // TODO: fix this use of $nextTick. It's probably a mistake
          // since it doesn't have a callback.
          // eslint-disable-next-line vue/valid-next-tick
          this.$nextTick();
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
